<template>
  <div class="text-left SignUpSignIn">
    <div v-if="isLoading">
      <LoaderComp />
    </div>
    <div v-else class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-7 MainSection">
        <div class="QuizellLogoAuth" @click="navigateToQuizell">
          <LazyImage
            src="https://quizell.com/cdn/shop/files/header-logo-new.svg?v=1711790205&width=600"
            :title="`Quizell login`"
            :alt="`Quizell login`"
            :loading="'lazy'"
          />
        </div>

        <div
          class="d-flex flex-column justify-content-center align-items-center"
          style="min-height: 100%"
        >
          <div class="TitleDiv">
            <h1 class="title">
              {{
                getActiveRoute("login") ? "Welcome Back" : "Set Up Your Account"
              }}
            </h1>
          </div>
          <div
            class="mt-4 w-100 d-flex justify-content-center align-items-center"
            v-if="!invitedLoginUser"
          >
            <div
              class="BtnGroup d-flex align-items-center justify-content-between"
            >
              <router-link
                to="/login"
                class="linkBtn"
                :class="getActiveBtn('login')"
              >
                Sign In
              </router-link>
              <router-link
                to="/sign-up"
                class="linkBtn"
                :class="getActiveBtn('sign-up')"
              >
                Sign Up
              </router-link>
            </div>
          </div>

          <div class="mt-4 FormDiv">
            <LoginForm
              v-if="getActiveRoute('login')"
              @hideApps="(e) => (hideApps = e)"
            />
            <SignUpForm
              v-if="getActiveRoute('sign-up')"
              @hideApps="(e) => (hideApps = e)"
            />
          </div>

          <div
            class="mt-4 platformDiv w-75"
            v-if="!hideApps && getActiveRoute('sign-up')"
          >
            <div class="continueDiv w-100 px-0 px-md-5">
              <p class="m-0 p-0 lineElement"></p>
              <p class="m-0 p-0 textElement">Or Continue With</p>
              <p class="m-0 p-0 lineElement"></p>
            </div>

            <div
              class="buttonDiv mt-3 d-flex justify-content-around align-items-center"
            >
              <!-- <div class="row m-0 p-0 w-100"> -->
              <div class="d-flex align-items-center justify-content-center">
                <a
                  href="https://apps.shopify.com/quizell-product-recommendation"
                  target="_self"
                >
                  <LazyImage
                    :src="'https://images.quizell.com/gallery/7551718698644.png'"
                    :key="'Shopify Quizell Sign up'"
                    :alt="'Login with Shopify'"
                    :title="'Big Commerce Quizell Sign up'"
                    class="rounded SignUpPlatformImg"
                    :loading="'lazy'"
                  />
                </a>
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <a
                  href="https://www.wix.com/app-market/quizell-product-quiz"
                  target="_self"
                >
                  <LazyImage
                    :src="'https://images.quizell.com/website/Button.png'"
                    :key="'wix Quizell Sign up'"
                    :alt="'Login with Shopify'"
                    :title="'Big Commerce Quizell Sign up'"
                    class="rounded SignUpPlatformImg"
                    :loading="'lazy'"
                  />
                </a>
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <a
                  style="
                    border-radius: 50%;
                    border: 1px solid gainsboro;
                    overflow: hidden;
                  "
                  href="https://www.bigcommerce.com/apps/quizell-product-quiz/"
                  target="_self"
                >
                  <LazyImage
                    :src="'https://images.quizell.com/website/Group%2069288.png'"
                    :key="'big Quizell Sign up'"
                    :alt="'Login with Big Commerce'"
                    :title="'Login with Big Commerce'"
                    class="rounded SignUpPlatformImg"
                    :loading="'lazy'"
                  />
                </a>
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <a
                  style="
                    border-radius: 50%;
                    border: 1px solid gainsboro;
                    overflow: hidden;
                  "
                  href="https://wordpress.org/plugins/quizell/"
                  target="_self"
                >
                  <LazyImage
                    :src="'https://images.quizell.com/website/Group%2069286.png'"
                    :key="'big Quizell Sign up'"
                    :alt="'Login with WooCommerce'"
                    :title="'Woo Commerce Quizell Sign up'"
                    class="rounded SignUpPlatformImg"
                    :loading="'lazy'"
                  />
                </a>
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <a
                  style="
                    border-radius: 50%;
                    border: 1px solid gainsboro;
                    overflow: hidden;
                  "
                  href="https://webflow.com/apps/detail/quizell-product-quiz"
                  target="_self"
                >
                  <LazyImage
                    :src="'https://images.quizell.com/website/Group%2069290.png'"
                    :key="'big Quizell Sign up'"
                    :alt="'Login with WorkFlow'"
                    :title="'WorkFlow Quizell Sign up'"
                    class="rounded SignUpPlatformImg"
                    :loading="'lazy'"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-none d-lg-block col-lg-5 objectSection lazyload"
        :data-bg="'https://images.quizell.com/website/SignUpNewBg.svg'"
      >
        <LazyImage
          v-show="getActiveRoute('login')"
          :src="'/assets/img/signIn_img.svg'"
          :title="`Quizell login`"
          :alt="`Quizell login`"
          style="height: 100%; width: 100%"
          :loading="'lazy'"
        />
        <LazyImage
          v-show="getActiveRoute('sign-up')"
          src="https://images.quizell.com/website/SignUpNewBg.svg"
          :title="`Quizell login`"
          :alt="`Quizell login`"
          style="height: 100%; width: 100%"
          :loading="'lazy'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LoaderComp from "../customize/component/LoaderComp.vue";
export default {
  name: "Login",
  computed: {
    invitedLoginUser() {
      return this.$route.query.invitedLogin || false;
    },
  },
  components: {
    LoaderComp,
    LoginForm: () =>
      import(/* webpackChunkName: "LoginForm" */ "./LoginForm.vue"),
    SignUpForm: () =>
      import(/* webpackChunkName: "SignUpForm" */ "./SignupForm.vue"),
  },

  data() {
    return {
      hideApps: false,
      isLoading: false,
    };
  },

  methods: {
    getActiveBtn(pathName) {
      return this.$route.name == pathName ? "ActiveLink" : null;
    },
    getActiveRoute(pathName) {
      return this.$route.name == pathName ? true : false;
    },
    navigateToQuizell() {
      window.open(`https://www.quizell.com`, "_self");
    },
  },
  created() {
    if (localStorage.getItem("isWorkSpaceUser")) {
      localStorage.removeItem("isWorkSpaceUser");
    }

    if (Object.entries(this.$route.query).length !== 0) {
      if (this.$route.query.token) {
        this.isLoading = true;
        localStorage.setItem("user_access_token", this.$route.query.token);
        this.$router.push("/manage/quizzes").then(() => { this.$router.go(0) })
      }
    }
    let user_access_token = localStorage.getItem("user_access_token");
    if (user_access_token !== null) {
      window.open("/", "_self");
    }
  },
};
</script>
<style>
.NewFormInput {
  border: 1px solid #dedede;
  border-radius: 12px;
  box-sizing: border-box;
  background: #ffffff;
  display: flex;
  padding: 14px 24px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.NewFormInput input {
  background: transparent;
  border: none;
  outline: none;
  color: #000;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.NewFormInput input:focus {
  border: none;
  outline: none;
}
.NewFormInput input::placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  text-align: start;

  color: #d1d1d1;
}

.NewFormInput-Btn {
  background: #4d1b7e;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}
.NewFormInput-Btn:hover {
  background: #ffc000;
  color: #ffffff;
}
.NewFormInput-Btn:disabled:hover {
  background: #4d1b7e !important;
}
.new-form-element {
  color: #161e34;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
</style>
<style scoped>
/* @font-face {
font-family: 'Gilroy'; 
src: 
     url('~@/assets/fonts/Gilroy-Light.otf')  format('opentype');

font-weight: normal;
font-style: normal;
} */
.SignUpSignIn {
  overflow-x: hidden;
}
.h2 {
  font-family: "Poppins";

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  color: #161e34;
}
.SignUpSignIn .MainSection {
  padding: 1% 5%;
  /* height:100%; */
  min-height: 100vh;
  overflow-y: scroll;
  background: #ffffff;
  position: relative;
}

.SignUpSignIn .MainSection .QuizellLogoAuth {
  position: absolute;
  top: 5%;
  left: 5%;
  cursor: pointer;
}

.MainSection .title {
  font-family: "Poppins";
  /* font-family: 'Gilroy'; */
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38.82px;
  text-align: center;
  color: #161e34;
}
.MainSection .titleDesc h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #161e34;
  padding-bottom: 20px;
}
.objectSection {
  background-color: #f6edff;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 560px) {
  .MainSection .title {
    font-weight: 500;
    font-size: 26px;
    line-height: 60px;
  }

  .MainSection .TitleDiv {
    padding: 28px;
  }
}

/* Btn Group Styles */

.BtnGroup {
  border-radius: 12px;
  background: #f0f0f2;
  padding: 5px;
  max-width: 500px;
}
.BtnGroup .linkBtn {
  display: flex;
  padding: 12px 56px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #7a7a7a;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.BtnGroup .linkBtn:hover {
  text-decoration: none;
  color: inherit;
}
.BtnGroup .linkBtn.ActiveLink {
  background: #fff;
  color: #000;
  font-size: 14px;
  border-radius: 8px;
  font-weight: 600;
}

.continueDiv {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: #969696;
  text-align: center;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.continueDiv .lineElement {
  height: 1px;
  flex-grow: 1;
  background: #969696;
}

.continueDiv .textElement {
  flex: 1;
}

.SignUpPlatformImg {
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.FormDiv {
  width: 50%;
}

@media (max-width: 768px) {
  .FormDiv {
    width: 100%;
  }
}
@media (max-width: 1500px) {
  .FormDiv {
    width: 70%;
  }
}
</style>
